import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Checkbox, Button, Typography } from '@material-ui/core';
import Loading from '../components/Loading.js';
import CustomSelect from '../components/CustomSelect';

import { useRetireHub, useRetiringHubs, useReplaceHub, useFactoryResetHub, useLastHubBackup } from '@domatic/query';
import { ErrorList } from '../components/index.js';
import { useInput } from '@domatic/hooks';

const Migration = ({ hub }) => {

    const { mutate: retireHub, isLoading: retiring, error: retireError } = useRetireHub();
    const { mutate: replaceHub, isLoading: replacing, error: replaceError } = useReplaceHub();
    const { mutate: factoryResetHub, isLoading: resetting, error: resetError } = useFactoryResetHub();
    const { data: retiringHubs } = useRetiringHubs(hub?.building);
    const { data: lastBackup } = useLastHubBackup(hub?.id);

    const replace = useInput(hub.replace ?? 'null', '', 'Target Hub');
    const candidates = useMemo(() => [{ name: 'None', id: 'null' }].concat(retiringHubs?.filter(h => h.id !== hub.id).map(hub => ({
        name: `${hub.name} (${hub.hostname})`,
        id: hub.id
    }))), [hub.id, retiringHubs]);

    const toggleRetiring = useCallback(async () => {
        await retireHub({ id: hub.id, retiring: !hub.retiring });
    }, [retireHub, hub]);

    const doReplace = useCallback(async () => {
        await replaceHub({ id: hub.id, target: replace.value === 'null' ? null : replace.value });
        replace.reset();
    }, [replaceHub, replace, hub]);

    const doFactoryReset = useCallback(async () => {
        await factoryResetHub({ id: hub.id });
        replace.reset();
    }, [factoryResetHub, replace, hub]);

    const loading = retiring || replacing || resetting;

    return (
        <Box p={2} width="100%">
            <Loading active={loading} spinner text="Saving...">
                <Typography variant="h5">Last Backup</Typography>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography>Branch: {lastBackup.branch}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography>Version: {lastBackup.version}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography>Key: {lastBackup.key}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <Typography>Created: {new Date(lastBackup.timestamp).toLocaleString()}</Typography>
                </Box>
                <br />
                <Typography variant="h5">Retire This Hub</Typography>
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <FormControlLabel
                        control={<Checkbox checked={hub.retiring} onChange={() => toggleRetiring()} />}
                        label="Retire This Hub"
                    />
                </Box>
                <br />
                <Typography variant="h5">Replace a Retired Hub With This Hub</Typography>
                <br />
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <CustomSelect field={replace} data={candidates} required fullWidth />
                    &nbsp;&nbsp;
                    <Button onClick={() => doReplace()} color='primary' disabled={!replace.changed}>Replace</Button>
                </Box>
                <br />
                <Typography variant="h5">Factory Reset This Hub</Typography>
                <br />
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                    &nbsp;&nbsp;
                    <Button onClick={() => doFactoryReset()} color='primary'>Factory Reset</Button>
                </Box>
                <ErrorList errors={[retireError, replaceError, resetError]} />
            </Loading>
        </Box>
    );
};

Migration.propTypes = {
    hub: PropTypes.object
};

export default Migration;

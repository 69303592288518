import PropTypes from 'prop-types';
import React from 'react';
import { useHubSettings, useUpdateSetting } from '@domatic/query';

import CustomSelect from '../components/CustomSelect';
import { Box, Typography, Button } from '@material-ui/core';

const HubLogLevels = ({ hub }) => {
    const { data: settings } = useHubSettings(hub.id);
    const { mutate: updateSetting } = useUpdateSetting(hub.id);
    const [ resetLevel, setResetLevel ] = React.useState('info');

    const levels = ['fatal', 'error', 'warn', 'info', 'verbose', 'debug'];

    const modules = settings?.Logging?.modules;

    const onLevelChange = (e, m) => {
        const target = modules.find(module => m.name === module.name);

        target.level = e.target.value;
        updateSetting({ type: 'Logging', modules: modules });
    };

    const onReset = () => {
        modules.forEach((module) => {
            module.level = resetLevel;
        });
        updateSetting({ type: 'Logging', modules: modules });
    };

    const levelist = levels?.map(name => ({ id: name, name: name }));

    return (
        <Box p={2}>
            <br />
            <Box>
                <Typography variant="h4">Logging</Typography>
                <br/>
                <>
                    <Typography variant="h5">Reset Level</Typography>
                    &nbsp;&nbsp;
                    <Box display="flex" flexDirection="column" alignItems="left">
                        <CustomSelect
                            field={{ value: resetLevel,  onChange: (e) => setResetLevel(e.target.value) }}
                            data={levelist}
                            required
                            fullWidth />
                        <br/>
                        <Button onClick={onReset}>  Reset All Modules  </Button>
                        <br/><br/>
                    </Box>
                </>
                <>
                    <Typography variant="h5">Module Levels</Typography>
                    &nbsp;&nbsp;
                    <Box display="flex" flexDirection="column" alignItems="left">
                        {modules?.sort((a, b) => a.name.localeCompare(b.name)).map((m, i) =>
                            <React.Fragment key={i}>
                                <CustomSelect
                                    field={{ ...m, value: m.level, label: m.name, onChange: (e) => onLevelChange(e, m) }}
                                    data={levelist}
                                    required
                                    fullWidth />
                                <br />
                            </React.Fragment>
                        )}
                    </Box>
                </>
            </Box>
        </Box>
    );
};

HubLogLevels.propTypes = {
    hub: PropTypes.object
};

export default HubLogLevels;

import storage from '@domatic/local-storage';
import { getQueryClient } from './queryClient';
import { useQuery, useMutation } from './queryClient';
import { useUserId } from './session';
import { useCreateObject, useUpdateObject, useDeleteObject } from './utils';
import { useOrganizations } from './subscription';

///// manage local storage holding current organization id

let _currentOrganizationIds = {};

const _startup = async () => {
    try {
        const value = await storage.getItem('current-organization');
        _currentOrganizationIds = JSON.parse(value) || {};
    }
    catch (error) {
        console.log('[organization _startup] error:', error);
        _currentOrganizationIds = {};
    }
    const queryClient = getQueryClient();
    queryClient.invalidateQueries(['currentOrganizationId']);
};
_startup();

const _setCurrentOrganizationId = userId => organizationId => {
    if (!organizationId || !userId) {
        console.warn('_setCurrentOrganizationId invalid');
    }
    else {
        _currentOrganizationIds[userId] = organizationId;
        const queryClient = getQueryClient();
        queryClient.setQueryData(['currentOrganizationId', userId], organizationId);
        storage.setItem('current-organization', JSON.stringify(_currentOrganizationIds));
    }
};

const _getCurrentOrganizationId = userId => () => _currentOrganizationIds?.[userId] ?? 'loading';

const useCurrentOrganizationId = () => {
    const userId = useUserId();
    const { data: organizationId } = useQuery(['currentOrganizationId', userId], _getCurrentOrganizationId(userId));
    return organizationId;
};

export const useSetCurrentOrganizationId = () => {
    const userId = useUserId();
    return useMutation(_setCurrentOrganizationId(userId), {
        onSuccess: () => {
            const queryClient = getQueryClient();
            queryClient.invalidateQueries(['currentOrganizationId', userId]);
        }
    });
};

const _getCurrentOrganization = (organizations, currentOrganizationId) => {
    const organization = organizations?.find(organization => organization.id === currentOrganizationId);
    if (organization) {
        return organization;
    }
    return undefined;
};

export const useCurrentOrganization = () => {
    const userId = useUserId();
    const currentOrganizationId = useCurrentOrganizationId();
    const { data: organizations } = useOrganizations();
    let organization = _getCurrentOrganization(organizations, currentOrganizationId);

    if (!organization && (organizations?.length || 0) > 0) {
        organization = organizations[0];
        console.log('defaulting to first organization:', organization.id);
        _setCurrentOrganizationId(userId)(organization.id);
    }
    return organization;
};

// export const useOrganizations = () => useRetrieveArray(['organization'], '/user/organizations');
export const useAddOrganization = () => useCreateObject(['organization'], '/user/organization');
export const useUpdateOrganization = () => useUpdateObject(['organization'], '/user/organization');
export const useDeleteOrganizationById = () => useDeleteObject(['organization'], '/user/organization');
export const getCurrentOrganizationId = userId => _currentOrganizationIds?.[userId];

export const useDeleteMember = organizationId => useDeleteObject([organizationId, 'member'], `/user/organization/${organizationId}/member`);

export const useSendInvitation = organizationId => useCreateObject([organizationId, 'invitation'], '/user/organization/invitation');
export const useResendInvitation = organizationId => useUpdateObject([organizationId, 'invitation'], '/user/organization/invitation');
export const useDeleteInvitation = organizationId => useDeleteObject([organizationId, 'invitation'], '/user/organization/invitation');

import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Button, Card, CardContent, CardActions, CardHeader } from '@material-ui/core';
import Loading from '../components/Loading';
import { useCardStyles } from '../hooks';
import { useInput } from '@domatic/hooks';
import { Center, Field, ErrorList, PasswordField } from '../components';
import { useAuthenticated, useLogin } from '@domatic/query';
import { useAsyncStorage } from '@domatic/local-storage';

const Login = () => {
    const [, setStoredAdmin] = useAsyncStorage('isAdmin', {});
    const classes = useCardStyles();

    const authenticated = useAuthenticated();

    const username = useInput('', 'emailOrUsername', 'Username or Email');
    const password = useInput('', '', 'Password');

    const [forgotRedirect, goForgot] = useState(false);
    const [signupRedirect, goSignup] = useState(false);
    const { mutate: login, isLoading: loading, error: loginError } = useLogin();

    const loginClick = () =>
        login({
            username: username.value,
            password: password.value
        }, {
            onSuccess: (resp) => {
                if (resp.isAdmin) {
                    setStoredAdmin(true);
                } else {
                    setStoredAdmin(false);
                }
            }
        });

    const invalidRedirect = loginError?.code === 'EINVALID';
    const formValid = username.valid && password.valid;
    const location = useLocation();

    if (invalidRedirect) {
        return (<Redirect to={`/invalid/${username.value}`} />);
    }

    if (authenticated) {
        return <Redirect to={location.state?.from ?? '/'} />;
    }

    if (forgotRedirect) {
        return <Redirect to='/forgot' />;
    }

    if (signupRedirect) {
        return <Redirect to='/signup' />;
    }

    return (
        <Center>
            <Card className={classes.card}>
                <Loading active={loading} spinner text="logging in...">
                    <CardHeader title="Log In" />
                    <CardContent>
                        <Field field={username} autoFocus required fullWidth />
                        <PasswordField field={password} current required fullWidth onEnter={loginClick} />
                        <ErrorList errors={[loginError]} />
                        <br />
                        <Button color="primary" onClick={loginClick} disabled={!formValid}>Log In</Button>
                    </CardContent>
                    <CardActions>
                        <Button variant="text" onClick={() => goForgot(true)}>Forgot password?</Button>
                        <Button variant="text" onClick={() => goSignup(true)}>Sign Up</Button>
                    </CardActions>
                </Loading>
            </Card>
        </Center>
    );
};

export default Login;

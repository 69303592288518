import asyncStorage from '@react-native-async-storage/async-storage';
export { useAsyncStorage } from './useAsyncStorage';

export const getItem = async key => {
    const value = window?.localStorage
        ? localStorage.getItem(key)
        : await asyncStorage.getItem(key);
    // console.log(`getItem(${key}) = ${value}`);
    return value;
};

export const setItem = async (key, value) => {
    // console.log(`setItem(${key}) = ${value}`);
    if (window?.localStorage) {
        localStorage.setItem(key, value);
    }
    else {
        await asyncStorage.setItem(key, value);
    }
};

export const removeItem = async key => {
    // console.log(`removeItem(${key})`);
    if (window?.localStorage) {
        localStorage.removeItem(key);
    }
    else {
        await asyncStorage.removeItem(key);
    }
};

export default { getItem, setItem, removeItem };

import { useHistory } from 'react-router-dom';
import { useAsyncStorage } from '@domatic/local-storage';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const AdminGuard = ({ children }) => {
    const [storedAdmin] = useAsyncStorage('isAdmin', {});
    const redirect = storedAdmin === false;
    const history = useHistory();
    // eslint-disable-next-line no-undef
    const isDev = process.env.NODE_ENV === 'development';

    useEffect(() => {
        if (!isDev && redirect) {
            console.log(window.location);
            window.location.href = '/manager';
            // history.push('/manager');
        }
    }, [history, isDev, redirect]);

    if (!isDev && redirect) { return null; }
    return children;
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

export default AdminGuard;

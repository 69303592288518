import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';

import { useHubSettings, useUpdateSetting, useDevices } from '@domatic/query';

export const PortControlSummary = ({ hub }) => {
    const { data: devices } = useDevices(hub?.id);
    const zeuses = devices?.filter(device => (device.__t === 'DeviceClass') && (device.type === 'zeus') && (device.activeStatus === 'active') && (device.archived !== true));
    // const zeus = zeuses?.[0]?.children.z;
    // const enables = zeus?.settings?.enables;

    const onStyle = { backgroundColor: 'lightgreen', color: 'black' };
    const offStyle = { backgroundColor: 'black', color: 'white' };

    const portBaseStyle = { margin: 1, padding: 5 };

    let p = 0;
    return (
        <Box display="flex" flex-direction="row">
            {
                zeuses.map((zeus, zi) => {
                    const enables = zeus?.children.z.status?.report?.ens;
                    return enables?.map((enable, ei) => {
                        p = p + 1;
                        return (
                            <div key={`${zi}-${ei}`} style={{ ...portBaseStyle, ...(enable ? onStyle : offStyle) }}>{p}</div>
                        );
                    });
                })
            }
        </Box>
    );
};

PortControlSummary.propTypes = {
    hub: PropTypes.object
};

export const PortControl = ({ hub }) => {
    const { mutate: updateSetting, isLoading: saving } = useUpdateSetting(hub.id);
    const { data: settings } = useHubSettings(hub?.id);
    const portState = settings?.PortControl?.state;

    const onStyle = { backgroundColor: 'lightgreen', color: 'black' };
    const offStyle = { backgroundColor: 'black', color: 'white' };

    const portBaseStyle = { margin: 3, padding: 8 };

    const togglePort = (i) => {
        const newPortState = portState;
        newPortState[i] = newPortState[i] ? 0 : 1;
        updateSetting({
            type: 'PortControl',
            setPortsTo: newPortState,
            setPortsCmdCnt: settings?.PortControl?.setPortsCmdCnt + 1
        });
    };

    const setAllPorts = (val) => {
        const newPortState = portState.map(() => val);
        updateSetting({
            type: 'PortControl',
            setPortsTo: newPortState,
            setPortsCmdCnt: settings?.PortControl?.setPortsCmdCnt + 1
        });
    };

    if (!portState) return null;
    return (
        <>
            <Box>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {portState.map((e, i) => (
                        <div key={i} style={{ ...portBaseStyle, ...(e ? onStyle : offStyle) }}>
                            <Typography>Port {i + 1} - {e ? 'ON' : 'OFF'}</Typography>
                            <Button
                                color='default'
                                onClick={() => togglePort(i)}
                                disabled={saving}>
                                {e ? 'Disable' : 'Enable'}
                            </Button>
                        </div>
                    ))}
                    <br />
                </div>
            </Box>
            <Box>
                <Button color='primary' onClick={() => setAllPorts(1)} disabled={saving}>Enable All</Button>
                &nbsp;&nbsp;
                <Button color='secondary' onClick={() => setAllPorts(0)} disabled={saving}>Disable All</Button>
            </Box>
        </>);
};

PortControl.propTypes = {
    hub: PropTypes.object
};

import { useBuildings, useCurrentBuilding, useSetCurrentBuildingId } from '@domatic/query';
import { Menu, Button, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        display: 'flex',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    // position: 'fixed',
    appBar: {
        position: 'static'
    }
}));

const BuildingMenu = () => {
    const classes = useStyles();

    // const isAdmin = useAdmin();
    const { data: buildings } = useBuildings();
    const currentBuilding = useCurrentBuilding();
    const { mutate: setCurrentBuildingId } = useSetCurrentBuildingId();

    return (
        <>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <>
                        <Button
                            variant="text"
                            color="inherit"
                            size="medium"
                            startIcon={<ApartmentIcon />}
                            {...bindTrigger(popupState)}>
                            <Typography noWrap className={classes.title}>{currentBuilding?.name}</Typography>
                        </Button>
                        <Menu
                            {...bindMenu(popupState)}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        >
                            {buildings?.map(
                                building => (
                                    <MenuItem
                                        key={building.id}
                                        selected={building.id === (currentBuilding?.id)}
                                        onClick={() => {
                                            popupState.close();
                                            setCurrentBuildingId(building.id);
                                        }}
                                    >
                                        {building.name}
                                    </MenuItem>)
                            )}
                        </Menu>
                    </>
                )}
            </PopupState >
        </>
    );
};

BuildingMenu.propTypes = {
};

export default BuildingMenu;

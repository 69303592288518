import axios from 'axios';
import { useMemo } from 'react';
import { useMutation } from './queryClient';

import { useSettings, updateSettings } from './settings';
import { useBuildings } from './subscription';
import { useRetrieveObject, useArrayItem, useDeleteObject, useCreateObject, useUpdateObject } from './utils';
import { useCurrentOrganization } from './organization';

export const useBuilding = buildingId => {
    const organizaton = useCurrentOrganization();
    const organizationId = organizaton?.id;
    return useArrayItem('buildings', buildingId, organizationId);
};

export const useCreateBuilding = () => useCreateObject(['building'], '/building');
export const useUpdateBuilding = () => useUpdateObject(['building'], '/building');
export const useDeleteBuilding = () => useDeleteObject(['building'], '/building');

export const getBuildingFloorplanUrl = async ({ buildingId, floor }) => {
    const resp = await axios.post('/building/floorplanUrl', { name: floor, buildingId });
    return resp.data.url;
};

export const uploadBuildingFloorplan = async ({ file, name, buildingId }) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('name', name);
    formData.append('buildingId', buildingId);
    await axios.post('/building/uploadFloorplan', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const useBuildingCommissionRunner = id => useRetrieveObject(['commissionRunner', id], `/building/jobs/commission/${id}`);

const _setCurrentBuildingId = (organizationId, buildingId) => {
    updateSettings({ [organizationId]: { currentBuildingId: buildingId } });
};

export const useSetCurrentBuildingId = () => {
    const organization = useCurrentOrganization();
    return useMutation(buildingId => _setCurrentBuildingId(organization?.id, buildingId));
};

export const useCurrentBuilding = () => {
    const { data: settings, isLoading: settingsLoading } = useSettings();
    const organization = useCurrentOrganization();
    const { data: buildings } = useBuildings();
    const currentBuilding = useMemo(() => {
        const currentId = settings?.[organization?.id]?.currentBuildingId;
        if (currentId && buildings && buildings.length > 0 && buildings.find(building => building.id === currentId)) {
            return buildings.find(building => building.id === currentId);
        }
        if (!settingsLoading && buildings && buildings.length > 0) {
            updateSettings({ [organization?.id]: { currentBuildingId: buildings[0].id } });
            return buildings[0];
        }
        return null;
    }, [settings, organization?.id, buildings, settingsLoading]);

    return currentBuilding;
};

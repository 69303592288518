import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ReactQueryDevtools } from '@domatic/query';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Vertical, Header, Body } from './components/Layout';
import FoyerBar from './components/FoyerBar';
import UserDialogs from './user/UserDialogs';
import Public from './pages/Public';
import Home from './pages/Home';
import AdminGuard from './guards/AdminGuard';
import { lightTheme, darkTheme } from './theme';

import { useAuthenticated, useAdmin, useProfile } from '@domatic/query';

const App = () => {

    const authenticated = useAuthenticated();

    const { data: profile } = useProfile();
    const isAdmin = useAdmin();

    const darkMode = authenticated && profile && !!profile.darkMode;
    const theme = darkMode ? darkTheme : lightTheme;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ReactQueryDevtools initialIsOpen={false} />
            <Router >
                <AdminGuard>
                    <Vertical>
                        <Header>
                            <FoyerBar authenticated={authenticated} isAdmin={isAdmin} />
                        </Header>
                        <Body>
                            <Switch>
                                <Route exact path="/">
                                    <Public />
                                </Route>
                                <Route path="/home">
                                    <Home />
                                </Route>
                            </Switch>
                        </Body>
                    </Vertical>
                </AdminGuard>
                <UserDialogs />
            </Router>
        </ThemeProvider>
    );
};

export default App;

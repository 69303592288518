import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import Loading from '../components/Loading';
import Grid from '../components/Grid';
import { useHubApplications, useDeleteApplication } from '@domatic/query';
import ApplicationDetail from './ApplicationDetail';

import { Box } from '@material-ui/core';
// import moment from 'moment';

const Applications = ({ hub }) => {
    const { path, url } = useRouteMatch();
    const history = useHistory();

    const { data: rows } = useHubApplications(hub.id);
    const { mutate: deleteApplication } = useDeleteApplication();

    const booleanFormatter = params => params.value ? '✓' : '';
    // const dateFormatter = useCallback(params => moment(params.value).calendar(), []);

    const columns = useMemo(() => ([
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 2 },
        { field: 'enabled', headerName: 'Enabled', flex: 1, valueFormatter: booleanFormatter },
        { field: 'branch', headerName: 'Branch', flex: 1 },
        { field: 'version', headerName: 'Version', flex: 1 }
    ]), []);

    const initialSortModel = [
        { field: 'name', sort: 'asc' }
    ];

    const active = false;
    const text = 'Loading...';

    const onAdd = () => {
        history.replace(`${url}/add`);
    };

    const onEdit = useCallback(toEdit => {
        const selection = rows.find(item => item?.id === toEdit[0]);
        history.replace(`${url}/edit/${selection?.id}`);
    }, [rows, history, url]);

    const onDelete = toDelete => {
        const selectedItem = rows.find(item => item.id === toDelete[0]);
        deleteApplication(selectedItem.id);
    };

    const onCancel = () => {
        history.replace(`${url}`);
    };

    return (
        <Box p={2} height="100%">
            <Loading active={active} spinner text={text}>
                <Switch>
                    <Route exact path={`${path}`}>
                        <Grid
                            title={'Applications'}
                            rows={rows}
                            columns={columns}
                            initialSortModel={initialSortModel}
                            onEdit={onEdit}
                            onAdd={onAdd}
                            onDelete={onDelete}
                        >
                        </Grid>
                    </Route>
                    <Route path={`${path}/add`}>
                        <ApplicationDetail hubId={hub?.id} onCancel={onCancel} />
                    </Route>
                    <Route path={`${path}/edit/:appId`}>
                        <ApplicationDetail hubId={hub?.id} onCancel={onCancel} />
                    </Route>
                </Switch>
            </Loading>
        </Box >
    );
};

Applications.propTypes = {
    building: PropTypes.object,
    hub: PropTypes.object
};

Applications.defaultProps = {};

export default Applications;

import { useState } from 'react';
import PropTypes from 'prop-types';

import MuiPhoneNumber from 'material-ui-phone-number';
import parsePhoneNumberFromString from 'libphonenumber-js';

import { Button, Typography, Box, Checkbox, FormControl, FormGroup, FormControlLabel, FormHelperText } from '@material-ui/core';

import { Field } from '../components';
import { useInput, useCheckbox } from '@domatic/hooks';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import UndoIcon from '@material-ui/icons/Undo';

const _minMaxLength = (min, max) => async value => {
    if (value.length === 0) return null;
    if (value.length < min) return `Length at least ${min}`;
    if (value.length > max) return `Length no more than ${max}`;
    return null;
};

const _length10 = async value => {
    if (value.length === 0) {
        return null;
    }
    if (value.length === 10) {
        return null;
    }
    return 'Length must be 10';
};

const ContactDetail = ({ title, contact, onSubmit, onCancel }) => {

    const name = useInput(contact?.name, 'notEmpty', 'Name');
    const unit = useInput(contact?.unit, ['number', _minMaxLength(3, 6)], 'Unit');
    const email = useInput(contact?.email, 'email', 'Email');

    const phoneEnabled = useCheckbox(contact?.phone && contact?.phone.length > 0);
    const [phone, setPhone] = useState(contact?.phone);
    const parsedPhone = parsePhoneNumberFromString(phone || '');
    const phoneValid = parsedPhone?.isValid();

    const code = useInput(contact?.code, ['number', 'notEmpty', _minMaxLength(4, 8)], 'Personal Door Code');

    const card = useInput(contact?.card, ['number', _length10], 'Access Card ID');

    const enableVisitor = useCheckbox(contact?.enableVisitor, 'Visitor');
    const enableDirect = useCheckbox(contact?.enableDirect, 'Visitor');
    const enableIntercom = useCheckbox(contact?.enableIntercom, 'Intercom');

    const visitorValid = enableVisitor.checked ? phoneEnabled.checked && phoneValid : true;
    const directValid = enableDirect.checked ? code.valid : true;
    const intercomValid = enableIntercom.checked ? phoneEnabled.checked && phoneValid : true;

    const formValid = name.valid &&
        unit.valid &&
        (phoneEnabled.checked ? phoneValid : true) &&
        visitorValid &&
        directValid &&
        intercomValid;

    const handleSubmit = () => {
        onSubmit({
            id: contact?.id,
            building: contact?.building,
            name: name.value,
            unit: unit.value,
            email: email.value,
            phone: (phoneEnabled.checked && phoneValid) ? parsedPhone.number : '',
            card: card.value,
            code: code.value,
            enableIntercom: enableIntercom.checked,
            enableVisitor: enableVisitor.checked,
            enableDirect: enableDirect.checked
        });
    };

    return (
        <Box p={2}>
            <Typography>{title}</Typography><br />
            <Field field={name} required fullWidth />
            <Field field={email} fullWidth />
            <Field field={unit} required fullWidth />
            <Box display="flex" alignItems="baseline">
                <Checkbox
                    checked={phoneEnabled.checked}
                    onChange={phoneEnabled.onChange}
                    color="primary"
                />
                <MuiPhoneNumber
                    label="Phone"
                    variant="outlined"
                    disabled={!phoneEnabled.checked}
                    fullWidth={true}
                    defaultCountry="us"
                    disableAreaCodes={true}
                    countryCodeEditable={false}
                    placeholder="Enter phone number"
                    value={phone}
                    onClick={() => phoneEnabled.setChecked(true)}
                    onChange={setPhone} />
            </Box>
            <br />

            <FormControl component="fieldset">
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={enableVisitor.checked} onChange={enableVisitor.onChange} name="visitor" />}
                        label="Enable Visitor Calling"
                    />
                </FormGroup>
                <FormHelperText>{
                    enableVisitor.checked
                        ? (unit.valid && phoneValid && phoneEnabled.checked
                            ? `Visitor enters *${unit.value}* to call this contact. (contact enters * during call to open door)`
                            : 'Need valid unit and phone numbers')
                        : ' '
                }</FormHelperText>
            </FormControl>
            <br />

            <FormControl component="fieldset">
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={enableIntercom.checked} onChange={enableIntercom.onChange} name="intercom" />}
                        label="Enable Intercom"
                    />
                </FormGroup>
                <FormHelperText>{
                    enableIntercom.checked ?
                        (phoneValid && phoneEnabled.checked
                            ? `Phone ${parsedPhone.number} will be called when intercom button is pressed`
                            : 'Need valid phone number')
                        : ' '
                }</FormHelperText>
            </FormControl>
            <br />
            <br />
            <Box display="flex" alignItems="start">
                <Checkbox
                    checked={enableDirect.checked}
                    onChange={enableDirect.onChange}
                    color="primary"
                />
                <Field
                    field={code}
                    disabled={!enableDirect.checked}
                    fullWidth
                    constraints={'[0-9]'}
                    onClick={() => enableDirect.setChecked(true)}
                    helperText={
                        unit.valid && enableDirect.checked && code.valid
                            ? `Tenant can enter ${unit?.value}*${code.value}# to open door.`
                            : 'Direct code entry not enabled'
                    }
                />
            </Box>
            <Typography></Typography>
            <Field field={card} constraints={'[0-9]'} fullWidth />
            <Button color="primary" onClick={handleSubmit} disabled={!formValid} startIcon={contact?.id ? <EditIcon /> : <AddIcon />}>{contact?.id ? 'Save' : 'Add'}</Button>
            &nbsp;
            &nbsp;
            <Button color="secondary" onClick={onCancel} startIcon={<UndoIcon />}>Cancel</Button>
            {/* <Typography>{contact?.id}</Typography> */}
        </Box>
    );
};

ContactDetail.propTypes = {
    contact: PropTypes.object,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func
};

ContactDetail.defaultProps = {};

export default ContactDetail;
